/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import "assets/css/bootstrap.min.css";
//@import "assets/css/bootstrap-5.0.5-alpha.min.css";
//@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import "assets/css/font-awesome.css";
@import "assets/css/lineicons.css";
@import "assets/css/weather-icons.css";
@import "assets/css/styles.css";
@import "assets/css/icofont.css";
@import "assets/css/line-awesome.css";
@import "assets/css/animated-headline.css";
@import "assets/css/mystyles.css";
@import '../node_modules/select2/dist/css/select2.min.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

/* @font-face {
    font-family: 'Glyphicons Halflings';
    src: url('assets/fonts/ fonts/glyphicons-halflings-regular.eot');
    src: url('assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype');
    src: url('assets/fonts/glyphicons-halflings-regular.woff2') format('woff2');
    src: url('assets/fonts/glyphicons-halflings-regular.woff') format('woff');
    src: url('assets/fonts/glyphicons-halflings-regular.ttf') format('truetype');
    src: url('assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
  } */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
